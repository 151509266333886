import {
  AvatarGroup,
  BoundMultiSelectField,
  BoundNumberField,
  BoundSelectField,
  Button,
  Css,
  FormHeading,
  SuperDrawerContent,
  SuperDrawerHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import {
  FeatureFlagDetailsFragment,
  FeatureFlagsDocument,
  FeatureFlagStatus,
  FeatureFlagTargetTarget,
  FeatureFlagType,
  InputMaybe,
  useFeatureFlagsMetadataQuery,
  UserFeatureFlagsDocument,
  useSaveFeatureFlagMutation,
} from "src/generated/graphql-types";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";

type FeatureFlagDrawerProps = {
  featureFlag?: FeatureFlagDetailsFragment;
};

export function FeatureFlagDrawer({ featureFlag }: FeatureFlagDrawerProps) {
  const isEdit = !!featureFlag;
  const { data } = useFeatureFlagsMetadataQuery({ variables: { all: isEdit } });
  const [saveFeatureFlag] = useSaveFeatureFlagMutation();
  const targets = featureFlag?.targets ?? [];
  const formState = useFormState({
    config,
    init: {
      id: featureFlag?.id,
      type: featureFlag?.type.code,
      developmentTargets: idsOfTargetsOfType(targets, "Development", "id"),
      projectTargets: idsOfTargetsOfType(targets, "Project", "id"),
      userTargets: idsOfTargetsOfType(targets, "User", "userId").compact(),
      userGroupTargets: idsOfTargetsOfType(targets, "UserGroup", "id"),
      status: featureFlag?.status.code ?? FeatureFlagStatus.Active,
      releaseVersion: featureFlag?.releaseVersion,
    },
  });
  const { closeDrawer } = useSuperDrawer();

  const developments = data?.developments ?? [];
  const projects = data?.projects ?? [];
  const users = data?.internalUsers ?? [];
  const availableFeatureFlagTypes = (data?.availableFeatureFlagTypes ?? []).map((type) => ({
    label: type.name,
    value: type.code,
  }));

  const handleSaveFeatureFlag = useCallback(async () => {
    const { developmentTargets, projectTargets, userTargets, userGroupTargets, ...others } = formState.value;
    await saveFeatureFlag({
      variables: {
        input: {
          ...others,
          targetIds: [
            ...(developmentTargets ?? []),
            ...(projectTargets ?? []),
            ...(userTargets ?? []),
            ...(userGroupTargets ?? []),
          ],
        },
      },
      refetchQueries: [FeatureFlagsDocument, UserFeatureFlagsDocument],
    });
    closeDrawer();
  }, [closeDrawer, formState.value, saveFeatureFlag]);

  return (
    <Observer>
      {() => (
        <SuperDrawerContent>
          <SuperDrawerHeader title={`${isEdit ? "Edit" : "Create"} Feature Flag`} />
          <div css={Css.df.fdc.gap2.h100.$}>
            <BoundSelectField
              label="Type"
              field={formState.type}
              options={availableFeatureFlagTypes}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
            <BoundSelectField
              label="Status"
              field={formState.status}
              options={Object.values(FeatureFlagStatus).map((status) => ({ id: status, value: status }))}
              getOptionLabel={({ id }) => id}
              getOptionValue={({ value }) => value}
            />
            <BoundNumberField
              label="Release Version"
              field={formState.releaseVersion}
              helperText="Increment this number to prompt users in this flag group to refresh their browser."
            />
            {formState.status.value === FeatureFlagStatus.Active && (
              <>
                <FormHeading title="Targets" />
                <BoundMultiSelectField
                  label="Developments"
                  field={formState.developmentTargets}
                  options={developments}
                />
                <BoundMultiSelectField label="Projects" field={formState.projectTargets} options={projects} />
                <BoundMultiSelectField
                  label="Users"
                  field={formState.userTargets}
                  options={users}
                  getOptionValue={({ user: { userId } }) => userId}
                  getOptionLabel={({ name }) => name}
                  fieldDecoration={internalUserAvatar}
                  getOptionMenuLabel={internalUserMenuLabel}
                />
                <BoundMultiSelectField
                  label="User Groups"
                  field={formState.userGroupTargets}
                  options={data?.userGroups ?? []}
                  getOptionMenuLabel={({ name, users }) => (
                    <div css={Css.w100.df.aic.jcsb.$}>
                      <div>{name}</div>
                      <AvatarGroup
                        avatars={users.map((u) => ({ src: u.internalUser?.avatar, name: u.internalUser?.name }))}
                      />
                    </div>
                  )}
                />
              </>
            )}
            <div css={Css.df.jcfe.$}>
              <Button label={isEdit ? "Save" : "Create"} onClick={handleSaveFeatureFlag} disabled={!formState.valid} />
            </div>
          </div>
        </SuperDrawerContent>
      )}
    </Observer>
  );
}

type FormValue = {
  id: InputMaybe<string>;
  type: InputMaybe<FeatureFlagType>;
  developmentTargets: InputMaybe<string[]>;
  projectTargets: InputMaybe<string[]>;
  userTargets: InputMaybe<string[]>;
  userGroupTargets: InputMaybe<string[]>;
  status: InputMaybe<FeatureFlagStatus>;
  releaseVersion: InputMaybe<number>;
};

const config: ObjectConfig<FormValue> = {
  id: { type: "value" },
  type: { type: "value", rules: [required] },
  developmentTargets: { type: "value" },
  projectTargets: { type: "value" },
  userTargets: { type: "value" },
  userGroupTargets: { type: "value" },
  status: { type: "value", rules: [required] },
  releaseVersion: { type: "value", rules: [required] },
};

type TargetType = FeatureFlagTargetTarget["__typename"];
type Target = FeatureFlagDetailsFragment["targets"][number]["target"];

function isTargetType<T extends TargetType>(type: T): (target: Target) => target is Extract<Target, { __typename: T }> {
  return function (target: Target): target is Extract<Target, { __typename: T }> {
    return target?.__typename === type;
  };
}

function targetsOfType<T extends TargetType>(
  targets: FeatureFlagDetailsFragment["targets"],
  type: T,
): Extract<Target, { __typename: T }>[] {
  return targets.map((t) => t.target).filter(isTargetType(type));
}

function idsOfTargetsOfType<T extends TargetType, F extends keyof Extract<Target, { __typename: T }>>(
  targets: FeatureFlagDetailsFragment["targets"],
  type: T,
  field: F,
) {
  return targetsOfType(targets, type).map((t) => t[field]);
}
